import React, {Component} from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"
import {Col, Row, Typography} from "antd";
import "./style.less";

const { Title, Paragraph } = Typography;

class AccountManager extends Component {

    componentWillMount() {
        if (isLoggedIn()) {
            navigate(`/app/account-manager`);
        }
    }

    render() {
        return (

            <StaticQuery
                query={graphql`
      {
        wordpressPage(slug: {eq: "account-manager"}) {
          id
          slug
          link
          wordpress_id
          wordpress_parent
          template
          title
          acf {
            intro_text
            name
            job_title
            phone_number
            email_address
            image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
            meta_title
            meta_description
            og_title
            og_description
            og_image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
            twitter_title
            twitter_card_type
            twitter_description
            twitter_image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
          }
        }
      }
    `}
                render={data => (
                    <div id={"accountManagerPage"}>
                        <Row className="darkbg" style={{ padding: 24, textAlign: 'center'}}>
                            <Col span={24}>
                                <Title level={1}>{data.wordpressPage.title}</Title>
                            </Col>
                        </Row>

                        <Row style={{ padding: 0, textAlign: 'left'}}>
                            <Col xs={{span: 24}} md={{span: 24, offset: 0}} style={{
                                background: `url(/banner_5.png)`,
                                backgroundPosition: 'center',
                                height: 300
                            }} >
                            </Col>
                        </Row>

                        <Row style={{ padding: 24, textAlign: 'center'}}>
                            <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}} >
                                <Paragraph>{data.wordpressPage.acf.intro_text}</Paragraph>
                            </Col>
                        </Row>

                        <Row style={{ padding: 24, textAlign: 'center'}}>
                            <Col span={24} style={{textAlign: 'center'}}>
                                <img src="/divider-001.png" style={{maxWidth: "100%"}} alt={"Ei Premier Club"} />
                            </Col>
                        </Row>

                        <Row style={{ padding: "0 24px", textAlign: 'center'}}>
                            <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 16, offset: 4}}>

                                <Row className={"infoRow"}>
                                    <Col className={"infoItem"} xs={24} md={24} lg={16}>
                                        <p className={"name"}>{data.wordpressPage.acf.name}</p>
                                        <p>{data.wordpressPage.acf.job_title}</p>
                                        <p><a href={"tel:" + data.wordpressPage.acf.phone_number}>{data.wordpressPage.acf.phone_number}</a></p>
                                        <p><a href={"mailto:" + data.wordpressPage.acf.email_address}>{data.wordpressPage.acf.email_address}</a></p>
                                    </Col>
                                    <Col className={"accManager-image"} xs={24} md={24} lg={8}>
                                        <img alt={data.wordpressPage.acf.name} src={data.wordpressPage.acf.image.localFile.childImageSharp.original.src} />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                    </div>
                )}
            >
            </StaticQuery>

        )

    }

}

export default AccountManager
