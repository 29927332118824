import React from "react"
import {Link, navigate} from "gatsby"
import {handleForgotPassword, isLoggedIn} from "../../services/auth"
import {Form, Icon, Input, Button, Card, Row, Col, Alert, Typography} from 'antd';

const { Title, Paragraph } = Typography;

class Forgot extends React.Component {
    state = {
        error: false,
        emailSent: false
    };

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                this.setState({error: true, message: 'The form is incomplete'});
                return false;
            }

            handleForgotPassword(values, (res) => {
                this.setState({ emailSent: true });
            });
        });
    };

    componentWillUnmount() {
        window.removeEventListener("change", this.handleUpdate, false);
    }

    componentWillMount() {
        if (isLoggedIn()) {
            navigate(`/app/profile`);
        }
    }

    goLogin(){
        navigate('/app/login');
    }

    render() {

        const { getFieldDecorator } = this.props.form;
        return (this.state.emailSent===false) ? (
            <Row>
                <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}}>
                    <Card bordered={false} hoverable={false} className="auth-form-wrapper">
                        <Title>Forgot Password</Title>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <Form.Item extra="Sends you a link to reset your password">
                                {getFieldDecorator('email', {
                                    rules: [
                                        {
                                            type: "email",
                                            message: "Invalid email address!",
                                            required: true,
                                        }
                                    ]
                                })(
                                    <Input
                                        prefix={<Icon type="mail" />}
                                        placeholder="Email"
                                        name="email"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                {this.state.error &&
                                <Alert type="error" message={this.state.message} banner />
                                }
                                <Button type="primary" htmlType="submit" className="login-form-button" block>
                                    Reset
                                </Button>
                            </Form.Item>
                        </Form>
                        <p>Know your password? <Link to="/app/login">Login</Link></p>
                    </Card>
                </Col>
            </Row>
        ) : (
            <Row>
                <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}}>
                    <Card bordered={false} hoverable={false} className="auth-form-wrapper">
                        <Paragraph style={{ textAlign: 'center'}}>
                            <Icon type="check-circle" className="success" />
                        </Paragraph>
                        <Paragraph style={{ textAlign: 'center'}}>An email has been sent to reset your password.</Paragraph>

                        <Button type="primary" htmlType="link" onClick={this.goLogin} className="login-form-button" block>
                            Log in
                        </Button>
                    </Card>
                </Col>
            </Row>
        )
    }
}

const WrappedNormalForgotPasswordForm = Form.create()(Forgot);

export default WrappedNormalForgotPasswordForm
