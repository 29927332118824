import React, {Component} from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"
import {Col, Row, Typography, Divider} from "antd";
import "./style.less";
import ExternalContent from "../../components/ExternalContent";


const { Title, Paragraph } = Typography;


class Unlocking extends Component {

    componentWillMount() {
        if (isLoggedIn()) {
            navigate(`/app/unlock-value`);
        }
    }

    render() {

        let faqCount = 0;
        let itemCount = 0;

        return (

            <StaticQuery
                query={graphql`
      {
        wordpressPage(slug: {eq: "unlock-value"}) {
          id
          slug
          link
          wordpress_id
          wordpress_parent
          template
          title
          acf {
            intro_text
            heading_1
            list_1 {
                item
            }
            heading_2
            list_2 {
                item
            }
            heading_3
            list_3 {
                item
            }
            heading_4
            list_4 {
                item
            }
            faqs {
                question
                answer
            }
            faq_heading
            meta_title
            meta_description
            og_title
            og_description
            og_image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
            twitter_title
            twitter_card_type
            twitter_description
            twitter_image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
          }
        }
      }
    `}
                render={data => (
                    <div id={"rewardingPage"}>
                        <Row className="darkbg" style={{ padding: 24, textAlign: 'center'}}>
                            <Col span={24}>
                                <Title level={1}>{data.wordpressPage.title}</Title>
                            </Col>
                        </Row>

                        <Row style={{ padding: 0, textAlign: 'left'}}>
                            <Col xs={{span: 24}} md={{span: 24, offset: 0}} style={{
                                background: `url(/banner_6.png)`,
                                backgroundPosition: 'center',
                                height: 300
                            }} >
                            </Col>
                        </Row>

                        <Row style={{ padding: 24, textAlign: 'center'}}>
                            <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}} >
                                <ExternalContent>
                                    <div dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.intro_text }} />
                                </ExternalContent>
                            </Col>
                        </Row>

                        <Row style={{ padding: "0 24px", textAlign: 'center'}}>
                            <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 16, offset: 4}}>

                                <Row className={"infoRow"}>
                                    <Col className={"infoItem"} xs={24} md={24} lg={6}>
                                        <Title level={4}>{data.wordpressPage.acf.heading_1}</Title>
                                        <ul>
                                            {data.wordpressPage.acf.list_1 && data.wordpressPage.acf.list_1.map(({item}) => {
                                                itemCount++;
                                                return <li key={"example-item-"+itemCount}>{item}</li>
                                            })}
                                        </ul>

                                    </Col>
                                    <Col className={"infoItem"} xs={24} md={24} lg={6}>
                                        <Title level={4}>{data.wordpressPage.acf.heading_2}</Title>
                                        <ul>
                                            {data.wordpressPage.acf.list_2 && data.wordpressPage.acf.list_2.map(({item}) => {
                                                itemCount++;
                                                return <li key={"example-item-"+itemCount}>{item}</li>
                                            })}
                                        </ul>
                                    </Col>
                                    <Col className={"infoItem"} xs={24} md={24} lg={6}>
                                        <Title level={4}>{data.wordpressPage.acf.heading_3}</Title>
                                        <ul>
                                            {data.wordpressPage.acf.list_3 && data.wordpressPage.acf.list_3.map(({item}) => {
                                                itemCount++;
                                                return <li key={"example-item-"+itemCount}>{item}</li>
                                            })}
                                        </ul>
                                    </Col>
                                    <Col className={"infoItem"} xs={24} md={24} lg={6}>
                                        <Title level={4}>{data.wordpressPage.acf.heading_4}</Title>
                                        <ul>
                                            {data.wordpressPage.acf.list_4 && data.wordpressPage.acf.list_4.map(({item}) => {
                                                itemCount++;
                                                return <li key={"example-item-"+itemCount}>{item}</li>
                                            })}
                                        </ul>
                                    </Col>
                                </Row>

                                <Row style={{ padding: 24, textAlign: 'center', margin: 0}}>
                                    <Col span={24}>
                                        <Title level={3}>{data.wordpressPage.acf.faq_heading}</Title>
                                    </Col>
                                </Row>

                                <Row style={{ padding: 24, textAlign: 'center'}}>
                                    <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 18, offset: 3}}>

                                        {data.wordpressPage.acf.faqs && data.wordpressPage.acf.faqs.map(({question,answer}) => {
                                            faqCount++;
                                            return <div key={"faq-item-"+faqCount}>
                                                <Paragraph className={"faq_title"}>{question}</Paragraph>
                                                <Paragraph>{answer}</Paragraph>
                                                <Divider />
                                            </div>
                                        })}

                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                    </div>
                )}
            >
            </StaticQuery>

        )

    }

}

export default Unlocking
