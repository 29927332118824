import React from "react"
import {Link, navigate} from "gatsby"
import {handleLogin, isLoggedIn} from "../../services/auth"
import { Form, Icon, Input, Button, Card, Row, Col, Alert, Typography } from 'antd';

const { Title } = Typography;

class Login extends React.Component {
    state = {
        error: false,
        message: ''
    };

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = event => {
        event.preventDefault();
        if(this.state.email === '' || this.state.password === ''){
            this.setState({error: true});
            return false;
        }
        this.props.form.validateFields((err, values) => {
            if (err) {
                this.setState({error: true, message: 'The form is incomplete'});
                return false;
            }

            handleLogin(values, (error, success) => {
                if(success === true) {
                    navigate(`/app/profile`);
                } else {
                    this.setState({error: true, message: 'Invalid login' });
                }
            });
        });

    };

    componentWillUnmount() {
        window.removeEventListener("change", this.handleUpdate, false);
    }

    componentWillMount() {
        if (isLoggedIn()) {
            navigate(`/app/profile`);
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Row>
                <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}}>
                    <Card bordered={false} hoverable={false} className="auth-form-wrapper">
                        <Title style={{textAlign: 'center'}}>Login</Title>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <Form.Item>
                                {getFieldDecorator('email', {
                                    rules: [
                                        {
                                            required: true,
                                        }
                                    ]
                                })(
                                    <Input

                                        prefix={<Icon type="mail" />}
                                        placeholder="Membership Code"
                                        name="email"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            message: "Enter a password",
                                            required: true,
                                        },
                                        {
                                            validator: this.validateToNextPassword,
                                        }
                                    ]
                                })(
                                    <Input.Password
                                        prefix={<Icon type="lock" />}
                                        placeholder="Password"
                                        name="password"
                                    />,
                                )}

                            </Form.Item>
                            <Form.Item>
                                {this.state.error &&
                                <Alert type="error" message={this.state.message} banner />
                                }
                                <Button type="primary" htmlType="submit" className="login-form-button" block>
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                        <p>Forgot your password? <Link to="/app/forgot-password">Oops...</Link></p>
                    </Card>
                </Col>
            </Row>

        )
    }
}

const WrappedNormalLoginForm = Form.create()(Login);

export default WrappedNormalLoginForm
