import React, {Component} from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"
import {Col, Row, Typography} from "antd";
import "./style.less";
import ExternalContent from "../../components/ExternalContent";


const { Title, Paragraph } = Typography;


class Rewarding extends Component {

    componentWillMount() {
        if (isLoggedIn()) {
            navigate(`/app/rewarding-excellence`);
        }
    }

    render() {

        let faqCount = 0;

        return (

            <StaticQuery
                query={graphql`
      {
        wordpressPage(slug: {eq: "rewarding-excellence"}) {
          id
          slug
          link
          wordpress_id
          wordpress_parent
          template
          title
          acf {
            intro_text
            reward_heading
            rewards{
                title
                description
            }
            meta_title
            meta_description
            og_title
            og_description
            og_image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
            twitter_title
            twitter_card_type
            twitter_description
            twitter_image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
          }
        }
      }
    `}
                render={data => (
                    <div id={"rewardingPage"}>
                        <Row className="darkbg" style={{ padding: 24, textAlign: 'center'}}>
                            <Col span={24}>
                                <Title level={1}>{data.wordpressPage.title}</Title>
                            </Col>
                        </Row>

                        <Row style={{ padding: 0, textAlign: 'left'}}>
                            <Col xs={{span: 24}} md={{span: 24, offset: 0}} style={{
                                background: `url(/banner_1.png)`,
                                backgroundPosition: 'center',
                                height: 300
                            }} >
                            </Col>
                        </Row>

                        <Row style={{ padding: 24, textAlign: 'center'}}>
                            <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}}>
                                <ExternalContent>
                                    <div dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.intro_text }} />
                                </ExternalContent>
                            </Col>
                        </Row>
                        <Row style={{ padding: 24, textAlign: 'center'}}>
                            <Col span={24} style={{textAlign: 'center'}}>
                                <img src="/divider-001.png" style={{maxWidth: "100%"}} alt={"Ei Premier Club"} />
                            </Col>
                        </Row>
                        <Row style={{ padding: 24, textAlign: 'center'}}>
                            <Col span={24} style={{textAlign: 'center'}}>
                                <Title level={3}>{data.wordpressPage.acf.reward_heading}</Title>
                            </Col>
                        </Row>
                        <Row className="rewards" style={{ padding: 24, textAlign: 'center'}}>
                            <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}}>
                                {data.wordpressPage.acf.rewards && data.wordpressPage.acf.rewards.map(({title,description}) => {
                                    faqCount++;
                                    return <div key={"faq-item-"+faqCount}>
                                        <Title level={4}>{title}</Title>
                                        <Paragraph>{description}</Paragraph>
                                    </div>
                                })}
                                <p style={{ paddingTop: 24, textAlign: 'center'}}>Please note rewards may be subject to change.</p>
                            </Col>
                        </Row>

                    </div>
                )}
            >
            </StaticQuery>

        )

    }

}

export default Rewarding
