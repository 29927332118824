import axios from 'axios';
import site from '../../data/site.yaml';
import { getCurrentUser, isLoggedIn} from './auth';

const isBrowser = typeof window !== `undefined`;


export const getPubData = (callback) => {
    if (!isBrowser) return false;
    const user = getCurrentUser();

    if( ! isLoggedIn()) return callback(true, null);

    axios({
        method: 'get',
        url: `${site.content_api_source}wp-json/brew/v1/pub/`,
        headers: {
            Authorization: `Bearer ${user.jwt}`
        }
    })
        .then(response => {
            //console.log(response);
            callback(false, response.data);
        })
        .catch(error => {
            // Handle error.
            if (error) callback(true, null)
        });

};


