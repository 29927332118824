import React, {Component} from "react"
import { getCurrentUser } from "../../services/auth"
import { getPubData } from "../../services/data"
import { StaticQuery, graphql, navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"
import {Col, Row, Typography} from "antd";
import "./style.less";
import ExternalContent from "../../components/ExternalContent";

const { Title } = Typography;

class Profile extends Component {
    state= {
        property_id: '',
        property_name: '',
        address_1: '',
        address_2: '',
        town: '',
        postcode: '',
        membership_code: '',
        attendee_1: '',
        attendee_2: '',
    }

    componentWillMount() {
        if (isLoggedIn()) {
            navigate(`/app/profile`);
        }
        getPubData((error, response) => {
            this.setState({
                property_id: response.property_id,
                property_name: response.property_name,
                address_1: response.address_1,
                address_2: response.address_2,
                town: response.town,
                postcode: response.postcode,
                membership_code: response.membership_code,
                attendee_1: response.attendee_1,
                attendee_2: response.attendee_2,
            })
        })
    }



    render() {

        return (
            <>
            <StaticQuery
                query={graphql`
      {
        wordpressPage(slug: {eq: "profile"}) {
          id
          slug
          link
          wordpress_id
          wordpress_parent
          template
          title
          content
          acf {
            logo1 {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
              alt_text
            }
            logo2 {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
              alt_text
            }
            meta_title
            meta_description
            og_title
            og_description
            og_image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
            twitter_title
            twitter_card_type
            twitter_description
            twitter_image {
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                }
              }
            }
          }
        }
      }
    `}
                render={data => (
                    <div id={"appProfile"}>
                        <Row className={'darkbg'} style={{ padding: 24, textAlign: 'center'}}>
                            <Col xs={{span: 24}} md={{span: 18, offset: 3}} >
                                <Title level={1}>{data.wordpressPage.title}</Title>
                            </Col>
                        </Row>

                        <Row style={{ padding: 0, textAlign: 'center'}}>
                            <Col xs={{span: 24}} md={{span: 24, offset: 0}} style={{
                                background: `url(/profile-banner.png)`,
                                backgroundPosition: 'center',
                                height: 300
                            }} >
                            </Col>
                        </Row>

                        <Row style={{ padding: 24, textAlign: 'center'}}>
                            <Col xs={{span: 24}} md={{span: 18, offset: 3}} style={{maxWidth: "100%", padding: 24}}>
                                <p><strong>Outlet Code:</strong> {this.state.property_id}</p>
                                <p><strong>Property Name:</strong> {this.state.property_name}</p>
                                <p><strong>Address 1:</strong> {this.state.address_1}</p>
                                <p><strong>Address 1:</strong> {this.state.address_2}</p>
                                <p><strong>Town:</strong> {this.state.town}</p>
                                <p><strong>Post Code:</strong> {this.state.postcode}</p>
                                <p><strong>Membership code:</strong> {this.state.membership_code}</p>
                                <p><strong>Name of attendee 1:</strong> {this.state.attendee_1}</p>
                                <p><strong>Name of attendee 2:</strong> {this.state.attendee_2}</p>
                            </Col>
                        </Row>

                        <Row style={{ padding: 24, textAlign: 'center'}}>
                            <Col xs={{span: 24}} md={{span: 6, offset: 6}} >
                                <img src={data.wordpressPage.acf.logo1.localFile.childImageSharp.original.src} style={{maxWidth: "80%"}} alt={data.wordpressPage.acf.logo1.alt_text} />
                            </Col>
                            <Col xs={{span: 24}} md={{span: 6, offset: 0}} >
                                <img src={data.wordpressPage.acf.logo2.localFile.childImageSharp.original.src} style={{maxWidth: "80%", paddingTop: 65, marginLeft: 32}} alt={data.wordpressPage.acf.logo2.alt_text} />
                            </Col>
                        </Row>

                    </div>
                )}
            >
            </StaticQuery>


            </>
        )

    }

}

export default Profile
