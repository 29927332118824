import React from "react"
import { navigate } from "gatsby"
import { handleResetPassword, isLoggedIn } from "../../services/auth"
import { Form, Icon, Input, Button, Card, Row, Col, Alert, Typography } from 'antd';

const queryString = require('query-string');
const { Title } = Typography;

class Reset extends React.Component {
    state = {
        error: false,
        code: ''
    };

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        // Check we have a verification code in the URL or redirect
        const { code } = queryString.parse(this.props.location.search);
        if(code) {
            this.state = ({code: code});
        } else {
            navigate('/app/login');
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        if (isLoggedIn()) {
            navigate(`/app/profile`);
        }
        this.props.form.validateFields((err, values) => {
            if (err) {
                this.setState({error: true, message: 'The form is incomplete'});
                return false;
            }

            // Add the verification code
            values.code = this.state.code;

            handleResetPassword(values, (res) => {
                if(res === true) {
                    navigate(`/app/login`);
                } else {
                    this.setState({error: true, message:'Something went wrong, please try again.'});
                }
            });
        });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords do not match');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true });
        }
        callback();
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    render() {
        if (isLoggedIn()) {
            navigate(`/app/profile`);
        }
        const { getFieldDecorator } = this.props.form;

        return (
            <Row>
                <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}}>
                    <Card bordered={false} hoverable={false} className="auth-form-wrapper">
                        <Title>Reset Password</Title>
                        <Form onSubmit={this.handleSubmit} className="register-form">
                            <Form.Item extra="Confirm your email address">
                                {getFieldDecorator('email', {
                                    rules: [
                                        {
                                            type: "email",
                                            message: "Invalid email address!",
                                            required: true,
                                        }
                                    ]
                                })(
                                    <Input
                                        prefix={<Icon type="mail" />}
                                        placeholder="Email"
                                        name="email"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            message: "Enter a password",
                                            required: true,
                                        },
                                        {
                                            validator: this.validateToNextPassword,
                                        }
                                    ]
                                })(
                                    <Input.Password
                                        prefix={<Icon type="lock" />}
                                        placeholder="Password"
                                        name="password"
                                    />,
                                )}

                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('confirmPassword', {
                                    rules: [
                                        {
                                            message: "Confirm your password",
                                            required: true,
                                        },
                                        {
                                            validator: this.compareToFirstPassword,
                                        }
                                    ]
                                })(
                                    <Input.Password
                                        prefix={<Icon type="lock" />}
                                        placeholder="Confirm password"
                                        name="password"
                                        onBlur={this.handleConfirmBlur}
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                {this.state.error &&
                                <Alert type="error" message={this.state.message} banner />
                                }
                                <Button type="primary" htmlType="submit" className="login-form-button" block>
                                    Update password
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        )
    }
}

const WrappedNormalRegisterForm = Form.create()(Reset);

export default WrappedNormalRegisterForm
