import React from "react";
import { Router } from "@reach/router";
import Wrapper from '../components/Wrapper';
import PrivateRoute from "../components/PrivateRoute";
import Profile from "../app/Profile/";
import Rewarding from "../app/Rewarding/";
import Unlocking from "../app/Unlocking/";
import AccountManager from "../app/AccountManager/";
import Login from "../app/auth/Login";
import Reset from "../app/auth/Reset";
import Forgot from "../app/auth/Forgot";

const App = () => (
    <Wrapper
        title={"My Account"}
    >
        <Router basepath="/app">
            <PrivateRoute path="/profile/" component={Profile} />
            <PrivateRoute path="/rewarding-excellence/" component={Rewarding} />
            <PrivateRoute path="/unlock-value/" component={Unlocking} />
            <PrivateRoute path="/account-manager/" component={AccountManager} />


            <Login path="/login/" component={Login} />
            <Reset path="/reset-password/" component={Reset} />
            <Forgot path="/forgot-password/" component={Forgot} />
        </Router>
    </Wrapper>
);

export default App;
